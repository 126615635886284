<template>
    <!-- Branches.vue -->
    <div>
        <HeaderBg title="النتائج و التقارير" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteReportsResult" 
            messege="هل أنت متأكد من مسح النتائج و التقارير ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                <input type="text" placeholder="البحث برقم الفرع">
            </div>
            <router-link v-if="add_reports_permission == true" to="/admin/results-and-reports/add-results-and-reports"> + إضافة نتائج تقارير </router-link>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الاسم</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="reportsResult in reportsResults" :key="reportsResult.id">
                        <td>{{reportsResult.translation[0].title}}</td>
                        <td>
                            <div class="options_container" v-if="edit_reports_permission == true || delete_reports_permission == true">
                                <img src="../../../assets/images/selectIcon.png" alt="">
                                <div class="hidden_options">
                                    <button v-if="edit_reports_permission == true" @click="() => editReportsResults(reportsResult.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                    <button v-if="delete_reports_permission == true" @click="() => deleteData(reportsResult.id)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="reportsResults.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
export default {
    name:'ResultsAndReports',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            reportsResults: [],
            deleteID: '',
            alertToggle: false,

            // permissions
            add_reports_permission: false,
            edit_reports_permission: false,
            delete_reports_permission: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list reports and results')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('add reports and results')){
            this.add_reports_permission = true
        } 
        if(permissions.includes('update reports and results')) {
            this.edit_reports_permission = true
        } 
        if(permissions.includes('delete reports and results')) {
            this.delete_reports_permission = true
        } 
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getResultsAndReports();
    },
    methods:{
        getResultsAndReports(){
            Request.fetch('admin/reportsResults', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.reportsResults = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        editReportsResults(id){
            this.$router.push(`/admin/results-and-reports/add-results-and-reports/${id}`)
        },
         // delete data
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteReportsResult(){
            Request.delete('admin/reportsResults',this.deleteID)
            .then( () => {
                this.getResultsAndReports();
            })
        },
         // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getResultsAndReports();
            } else{
                this.apiParams = `?page=${val}`;
                this.getResultsAndReports();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.filter{
    select{
        background-color: #FFF;
        border: 0;
        width: 89px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
}
.main_table table .options_container {
    display: flex;
    justify-content: flex-end;
    width: 10px;
    margin: 0 auto 0 32px;
}


</style>