<template>
    <div>
        <HeaderBg :img="img" title="الطلبات" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteorder" 
            messege="هل أنت متأكد من إلغاء الطلب ؟"
        />
        <header class="admin_content_header">
            <div class="filter">
                <select @change="(e) => statusFilter(e)">
                    <option value="" selected disabled>الحالة</option>
                    <option value=""> الكل </option>
                    <option value="1"> قيد التنفيذ </option>
                    <option value="2"> مقبول </option>
                    <option value="3"> مرفوض </option>
                </select>
                <!-- <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div> -->
            </div>
            <div class="search">
                <form @submit.prevent="searchByName">
                    <div class="inputSearch">
                        <input type="text" placeholder="البحث بالإسم" v-model="nameSearch">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
            </div>
            <div>
                <button class="saveBtn" @click="downloadRequests"> تصدير الطلبات </button>
            </div>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />

        <div class="request_table_container" v-if="loadingRequest == false">
            <div class="tabel_header">
                <div class="block">رقم الطلب</div>
                <div class="block">بيانات العميل</div>
                <div class="block">العنوان</div>
                <div class="block">الفرع</div>
                <div class="block">الحالة</div>
                <div class="block">الدفع</div>
                <div class="block">إجمالي الطلب</div>
                <div class="block">نوع الطلب</div>
                <div class="block"></div>
                <div class="block"></div>
            </div>

            <div class="detailsTable">
                <div class="table_row" v-for="order in orders" :key="order.id">
                    <div class="block">
                        <span>#{{order.id}}</span>
                    </div>
                    <div class="block">
                        <router-link class="useLink" :to="'users/add-user/' + order.user.id">{{order.user.name}}</router-link>
                        <h3>{{order.user.mobile}}</h3>
                    </div>
                    <div class="block">
                        <a class="saveBtn" target="_blank" :href="order.address"> العنوان </a>
                    </div>
                    <div class="block">
                        <h4>{{order.branch.code}}</h4>
                    </div>
                    <div class="block">
                        <h4 class="Processing" v-if="order.status.name == 'قيد التنفيذ'">{{order.status.name}}</h4>
                        <h4 class="deliverd" v-if="order.status.name == 'مقبول'">{{order.status.name}}</h4>
                        <h4 class="cancel" v-if="order.status.name == 'مرفوض'">{{order.status.name}}</h4>
                        <h4 class="cancel" v-if="order.status.name == 'ملغي'">{{order.status.name}}</h4>
                        <h4 class="deliverd" v-if="order.status.name == 'تم التوصيل'">{{order.status.name}}</h4>
                        <select @change="(e) => updateStatus(e, order.id)" >
                            <option value="" selected disabled>إختار الحالة</option>
                            <option :value="status.id" v-for="status in orderStatus" :key="status.id"> {{status.translation[0].name}} </option>
                        </select>
                        <h3>{{order.date}}</h3>
                    </div>
                    <div class="block">
                        <h6> {{order.payment_method.translation.title}} </h6>
                    </div>
                    <div class="block">
                        <h5>{{order.price}}</h5>
                        <h3>ريال إجمالي الفاتورة</h3>
                    </div>
                    <div class="block">
                        <h3 class="des">{{order.wasfy == 1 ? 'وصفي' : 'غير وصفي'}}</h3>
                    </div>
                     <div class="block">
                        <button class="saveBtn" v-if="deleteOrderLoading == false && order.status.name !== 'ملغي'" @click="() => deleteOrder(order.id)"> إلغاء الطلب </button>
                        <button class="saveBtn" v-if="deleteOrderLoading == true"> <b-spinner></b-spinner> </button>
                    </div>
                    <div class="block">
                        <div class="detailsBtn" @click="order.isCollapsed = !order.isCollapsed">
                            <b-icon icon="eye" aria-hidden="true"></b-icon>
                            التفاصيل
                        </div>
                        <div class="reciteBtn" @click="order.billCollapsed = !order.billCollapsed">
                            <b-icon icon="newspaper" aria-hidden="true"></b-icon>
                            الفاتورة
                        </div>
                    </div>

                    <div class="details_popUp" v-if="order.isCollapsed == true">
                        <div class="details_container">
                            <div class="close_popUp"  @click="order.isCollapsed = !order.isCollapsed">
                                <b-icon icon="x" aria-hidden="true"></b-icon>
                            </div>

                            <div class="steps">
                                <div class="roundStep">
                                    <div class="number">
                                        1
                                        <div class="number_description">
                                            <h3>تم استلام الطلب</h3>
                                            <p>2023-10-07</p>
                                            <p>09:09 AM</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="stepLine"></div>
                                <div class="roundStep">
                                    <div class="number">
                                        2
                                        <div class="number_description">
                                            <h3>تم تأكيد الطلب </h3>
                                            <h3>وجاري تحضيره</h3>
                                            <p>2023-10-07</p>
                                            <p>09:09 AM</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="stepLine"></div>
                                <div class="roundStep">
                                    <div class="number">
                                        3
                                        <div class="number_description">
                                            <h3>الطلب خرج للتوصيل </h3>
                                            <h3>مع المندوب</h3>
                                            <p>2023-10-07</p>
                                            <p>09:09 AM</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="stepLine"></div>
                                <div class="roundStep">
                                    <div class="number">
                                        4
                                        <div class="number_description">
                                            <h3>تم التوصيل</h3>
                                            <p>2023-10-07</p>
                                            <p>09:09 AM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="order_details">
                                <div class="block">
                                    <h4>رقم الطلب</h4>
                                    <h3>#{{order.id}}</h3>
                                </div>
                                <div class="block">
                                    <h4>بيانات العميل</h4>
                                    <h3>{{order.user.name}}</h3>
                                    <h3>{{order.user.mobile}}</h3>
                                </div>
                                <div class="block">
                                    <h4>العنوان</h4>
                                    <a class="saveBtn" target="_blank" :href="order.address"> العنوان </a>
                                </div>
                                <div class="block">
                                    <h4>الفرع</h4>
                                    <h3><h4>{{order.branch.code}}</h4></h3>
                                </div>
                                <div class="block">
                                    <h4>الدفع</h4>
                                    <h3>{{order.payment_method.translation.title}}</h3>
                                    <!-- <h3>{{order.date}}</h3> -->
                                </div>
                                <div class="block">
                                    <h4>إجمالي الطلب</h4>
                                    <h3>{{order.price}}</h3>
                                </div>
                                <div class="block">
                                    <h4>نوع الطلب</h4>
                                    <h3 class="des">{{order.wasfy == 1 ? 'وصفي' : 'غير وصفي'}}</h3>
                                </div>
                                <div class="block alignFlex">
                                    <div class="reciteBtn" @click="order.billCollapsed = !order.billCollapsed">
                                        <b-icon icon="newspaper" aria-hidden="true"></b-icon>
                                        الفاتورة
                                    </div>
                                </div>
                            </div>

                            <div class="clientStatus">
                                <div class="block_header">
                                    <h3>معلومات حالة العميل</h3>
                                    <b-icon @click="clientStatus = !clientStatus" v-if="clientStatus == false" icon="chevron-down" aria-hidden="true"></b-icon>
                                    <b-icon @click="clientStatus = !clientStatus" v-if="clientStatus == true" icon="chevron-up" aria-hidden="true"></b-icon>
                                </div>
                                <div class="block_body" v-if="clientStatus == true">
                                    <div class="detail">
                                        <h4>تاريخ الميلاد</h4>
                                        <h3>{{order.user.birth_date}}</h3>
                                    </div>
                                    <div class="detail">
                                        <h4>الجنس</h4>
                                        <h3>{{order.user.gender == 'male' ? 'ذكر' : 'أنثي'}}</h3>
                                    </div>
                                    <div class="detail">
                                        <h4>المدينة</h4>
                                        <h3>{{order.user.city}}</h3>
                                    </div>
                                    <div class="detail">
                                        <h4>الهاتف</h4>
                                        <h3>{{order.user.mobile}}</h3>
                                    </div>
                                </div>

                            </div>

                            <div class="popUp_order_details">
                                <div class="order_header">
                                    <h3>تفاصيل الطلب</h3>
                                </div>
                                <div class="order_table_header">
                                    <div class="block">
                                        <h4>صورة المنتج</h4>
                                    </div>
                                    <div class="block">
                                        <h4>وصف المنتج</h4>
                                    </div>
                                    <div class="block">
                                        <h4>رقم الصنف</h4>
                                    </div>
                                    <div class="block">
                                        <h4>السعر</h4>
                                    </div>
                                    <div class="block">
                                        <h4>الوصفة</h4>
                                    </div>
                                    <div class="block">
                                        <h4>الكمية</h4>
                                    </div>
                                    <div class="block">
                                        <h4>المجموع</h4>
                                    </div>
                                </div>
                                <div class="order_body" v-for="detail in order.details" :key="detail.id">
                                    <div class="block">
                                        <div class="img_container">
                                            <img :src="detail.image" alt="">
                                        </div>
                                    </div>
                                    <div class="block">
                                        <h3>{{detail.product}}</h3>
                                    </div>
                                    <div class="block">
                                        <h3>{{detail.product_code}}</h3>
                                    </div>
                                    <div class="block">
                                        <h3>{{detail.price}}</h3>
                                    </div>
                                    <div class="block">
                                        <a class="saveBtn" :href="detail.download_file + '?token=' + accessToken" target="_blank">تنزيل الوصفة</a>
                                    </div>
                                    <div class="block">
                                        <h3>{{detail.amount}}</h3>
                                    </div>
                                    <div class="block">
                                        <h3>{{detail.price}}</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="money">
                                <div class="detailed_Money">
                                    <div>
                                        <h3>الاجمالي</h3>
                                        <h3>{{order.discount ? order.total_before_discount : order.price}}ريال</h3>
                                    </div>
                                    <div v-if="order.discount">
                                        <h3>الخصم</h3>
                                        <h3>{{order.discount}}</h3>
                                    </div>
                                    <!-- <div>
                                        <h3>رسول التوصيل</h3>
                                        <h3>0</h3>
                                    </div> -->
                                    <div class="breakLine"></div>
                                    <div class="total">
                                        <h3>المستحق</h3>
                                        <h3>{{order.discount ? order.total_after_discount : order.price}} ريال</h3>
                                    </div>
                                </div>
                                <div>
                                    <button  @click="order.isCollapsed = !order.isCollapsed" class="saveBtn">الرجوع</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="Bill_container" v-if="order.billCollapsed == true">
                        <div class="header p-3 d-flex justify-content-between">
                            <div>
                                <button class="saveBtn"  @click="saveAsImage">  طباعة </button>
                            </div>
                            <div>
                                <b-icon icon="x" @click="order.billCollapsed = !order.billCollapsed"></b-icon>
                            </div>
                        </div>

                        <div class="bill_body p-3" id="divToSave">
                            <div class="mujtamaa_info" style="display: flex; justify-content: space-between; direction: rtl;">
                                <div class="info" style="direction: rtl;">
                                    <h2 style="font-size:24px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;">صيدلية المجتمع الحديثة الطبية</h2>
                                    <p style="font-size:16px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;">8836 طريق المدينة - حي الحمدية - 3213</p>
                                    <p style="font-size:16px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;"> جدة - المملكة العربية السعودية </p>
                                    <p style="font-size:16px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;"> الرقم الضريبي 310669105700:vat No   رقم السجل التجاري 4030440956:C.R. </p>
                                    <p style="font-size:16px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;"></p>
                                    <p style="font-size:16px; font-weight:600; margin-bottom:5px; color:#444444; direction: rtl;"></p>
                                </div>
                                <div class="logo" style="direction: rtl;">
                                    <img style="width:150px; direction: rtl;" src="../../../assets/images/newLogo.png" alt="">
                                    <p style="font-size: 16px; font-weight: 600; direction: rtl;">www.almujtama.com.sa</p>
                                </div>
                            </div>
                            <div class="line" style="width:100%; background-color:#444444; height:2px; margin:15px 0;"></div>
                            <div style="display:flex; justify-content:space-between; align-items: center; width: 100%;">
                                <div>

                                </div>
                                <div style="display:flex; justify-content:center; align-items: center; flex-direction:column">
                                    <h3 style="font-size: 28px; font-weight: 600; text-align:center;">فاتورة ضريبية مبسطة</h3>
                                    <div style="display:flex; justify-conent: space-between">
                                        <span style="font-size: 18px; margin-left:30px;"> رقم الطلب : {{order.branch.code}} </span>
                                        <span style="font-size: 18px"> التاريخ و الوقت : {{order.date}} </span>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>

                            <div style="width: 100%; margin-top: 40px;">
                                <table class="fl-table; width: 100%">
                                    <thead style="background-color:#F3C100">
                                        <tr style="background-color:unset">
                                            <th style="color: #000; border: 1px solid #000;">إسم العميل</th>
                                            <th style="color: #000; border: 1px solid #000;">الهاتف</th>
                                            <th style="color: #000; border: 1px solid #000;">طريقة الدفع</th>
                                            <th style="color: #000; border: 1px solid #000;">المدينة/الحي</th>
                                            <!-- <th style="color: #000; border: 1px solid #000;">العنوان</th> -->
                                            <th style="color: #000; border: 1px solid #000;">ملاحظات</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="color: #000; border: 1px solid #000;">{{order.user.name}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{order.user.mobile}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{order.user.name}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{order.payment_method.translation.title}}</td>
                                            <!-- <td style="color: #000; border: 1px solid #000;">{{order.address}}</td> -->
                                            <td style="color: #000; border: 1px solid #000;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div style="width: 100%; margin-top: 40px;">
                                <table class="fl-table; width: 100%">
                                    <thead style="background-color:#F3C100">
                                        <tr style="background-color:unset">
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>م</div>
                                                <div>No</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>رقم الصنف</div>
                                                <div>Item No</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>مرجع الدفع</div>
                                                <div>Payment reference</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>اسم الصنف</div>
                                                <div>Item Description</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>السعر قبل الضريبة</div>
                                                <div>Price</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>قيمة الضريبة</div>
                                                <div>VAT amount</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>الكمية</div>
                                                <div>Quantity</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>الخصم</div>
                                                <div>Discount Amount</div>
                                            </th>
                                            <th style="color: #000; border: 1px solid #000;">
                                                <div>المجموع</div>
                                                <div>Total</div>
                                            </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-for="(detail, index) in order.details" :key="detail.id">
                                            <td style="color: #000; border: 1px solid #000;">{{index + 1}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{detail.id}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{order.tran_ref}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{detail.product}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{detail.price_before_vat}}</td>
                                            <td style="color: #000; border: 1px solid #000;"> {{detail.vat}} </td>
                                            <td style="color: #000; border: 1px solid #000;">{{detail.amount}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{order.discount ? order.discount : 0}}</td>
                                            <td style="color: #000; border: 1px solid #000;">{{detail.price}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div style="width: 100%; margin-top: 40px; display:flex; justify-conent:spacce-between;">
                                <div style="display:flex; flex-wrap:wrap; border: 1px solid #000; width: 70%;">
                                    <div style="width:40%; padding:10px; border-bottom: 1px solid #000; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        الإجمالي قبل الضريبة Sub-total:
                                    </div>
                                    <div style="width:60%; padding:10px; border-bottom: 1px solid #000; font-size: 18px; font-weight:600;">
                                        {{order.total_after_vat}}
                                    </div>
                                    <div style="width:40%; padding:10px; border-bottom: 1px solid #000; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        إجمالي قيمة الضريبة المضافة VAT Total:
                                    </div>
                                    <div style="width:60%; padding:10px; border-bottom: 1px solid #000; font-size: 18px; font-weight:600;">
                                        {{order.vat}}
                                    </div>
                                    <div style="width:40%; padding:10px; border-bottom: 1px solid #000; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        مصاريف الشحن Shipping Fees:
                                    </div>
                                    <div style="width:60%; padding:10px; border-bottom: 1px solid #000; font-size: 18px; font-weight:600;">
                                        0
                                    </div>
                                    <div style="width:40%; padding:10px; border-bottom: 1px solid #000; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        قيمه الخصم :
                                    </div>
                                    <div style="width:60%; padding:10px; border-bottom: 1px solid #000; font-size: 18px; font-weight:600;">
                                        {{order.discount ? order.discount : 0}}
                                    </div>
                                    <div style="width:40%; padding:10px; border-bottom: 1px solid #000; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        الإجمالي (ر.س) Total SAR:
                                    </div>
                                    <div style="width:60%; padding:10px; border-bottom: 1px solid #000; font-size: 18px; font-weight:600;">
                                        {{order.total_after_vat}}
                                    </div>
                                    <div style="width:40%; padding:10px; border-left: 1px solid #000; font-size: 18px; font-weight:600;">
                                        عدد الوحدات Total PCS:
                                    </div>
                                    <div style="width:60%; padding:10px; font-size: 18px; font-weight:600;">
                                        {{order.details.length}}
                                    </div> 
                                </div>
                            </div>
                            <div style="width: 100%; margin-top: 40px; display:flex; justify-conent:spacce-between;">
                                <div style="display:flex; flex-wrap:wrap; border: 1px solid #000; width: 100%;">
                                    <div style="width:60%; padding:10px; border-left: 1px solid #000;">
                                        <ul>
                                            <li style="font-size: 16px; font-weight:600;">
                                                .تم استلام الأصناف بحالة جيدة و مطابقة للفاتورة
                                            </li>
                                            <li style="font-size: 16px; font-weight:600;">
                                                تم توصيل الأصناف فى سيارة مبردة و تم استلامها فى درجه الحرارة الموصي بها حسب كل صنف.
                                            </li>
                                            <li style="font-size: 16px; font-weight:600;">
                                                تم شرح طريقة استخدام الدواء من قبل الصيدلي (للأدوية الوصفية)
                                            </li>
                                            <li style="font-size: 16px; font-weight:600;">
                                                يوجد ملصق ارشادات الإستخدام على جميع عبوات الأدوية
                                            </li>
                                        </ul>
                                    </div>
                                    <div style="width:40%; padding:10px; font-size: 18px; font-weight:600;">
                                        التوقيع
                                    </div>
                                </div>
                            </div>
                            
                            <div style="display:flex; align-items:center; margin-top:40px;">
                                <span style="font-size:12px; font-weight:600;">طريقة الدفع (فى حالة الدفع عند الإستلام فقط)</span>
                                <div style="display:flex; align-items:center; margin:0 20px;">
                                    <div style="width:20px; height:20px; border:1px solid #000; margin-left:5px;"></div>
                                    <span style="font-size:12px; font-weight:600;">كاش</span>
                                </div>
                                <div style="display:flex; align-items:center; margin-left:20px;">
                                    <div style="width:20px; height:20px; border:1px solid #000; margin-left:5px;"></div>
                                    <span style="font-size:12px; font-weight:600;">مدي</span>
                                </div>
                                <div style="display:flex; align-items:center; margin-left:20px;">
                                    <div style="width:20px; height:20px; border:1px solid #000; margin-left:5px;"></div>
                                    <span style="font-size:12px; font-weight:600;">فيزا كارد</span>
                                </div>
                                <div style="display:flex; align-items:center; margin-left:20px;">
                                    <div style="width:20px; height:20px; border:1px solid #000; margin-left:5px;"></div>
                                    <span style="font-size:12px; font-weight:600;">ماستر كارد</span>
                                </div>
                                <div style="display:flex; align-items:center; margin-left:20px;">
                                    <div style="width:20px; height:20px; border:1px solid #000; margin-left:5px;"></div>
                                    <span style="font-size:12px; font-weight:600;">STC PAY</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination mt-5" v-if="orders.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import html2canvas from 'html2canvas';
import Alert from '../../global/Alert/Alert';
export default {
    name: 'Requests',
    components: {HeaderBg, RequestSpinner, Alert},
    data(){
        return{
            loadingRequest: true,
            deleteOrderLoading: false,
            img: require('../../../assets/images/requests.png'),
            orders: [],
            orderStatus: [],
            clientStatus : false,
            nameSearch: '',
            // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
            accessToken: '',

            deleteID: '',
            alertToggle: false,
        }
    },
    mounted(){
        this.accessToken = localStorage.getItem('token')
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getRequests();
        this.getOrderStatus();
    },
     methods:{
        downloadRequests(){
            Request.fetch('admin/export-orders','','')
                .then(response => {
                    // this.loadingRequest = false;
                    console.log(response)
                    // Assuming the response contains binary data
                        const blob = new Blob([response.data], { type: 'application/octet-stream' }); // Set the MIME type accordingly
                        const url = window.URL.createObjectURL(blob);
                        
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'exported-orders.csv'; // Set the desired file name and extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        
                        // Release the object URL after the download
                        window.URL.revokeObjectURL(url);
                })
        },
        saveAsImage() {
            const divToSave = document.getElementById('divToSave')
            html2canvas(divToSave).then(canvas => {
            const imageUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'div_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            });
        },
        statusFilter(e){
            this.loadingRequest = true;
            if(e.target.value == ''){
                this.getRequests();
            } else {
                Request.fetch('admin/orders', this.apiParams , {
                    limit: store.state.limit,
                    order_status_id: e.target.value
                })
                .then(response => {
                    this.loadingRequest = false;
                    this.orders = response.data.data
                    this.pages = Math.ceil(response.data.total / store.state.limit) 
                    this.orders = response.data.data.map(developer => {
                        return {
                            ...developer,
                            isCollapsed: false
                        };
                    });
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
        searchByName(){
            this.loadingRequest = true;
            if(this.nameSearch == ''){
                this.getRequests();
            } else {
                Request.fetch('admin/orders', this.apiParams , {
                    limit: store.state.limit,
                    username: this.nameSearch
                })
                .then(response => {
                    this.loadingRequest = false;
                    this.orders = response.data.data
                    this.pages = Math.ceil(response.data.total / store.state.limit) 
                    this.orders = response.data.data.map(developer => {
                        return {
                            ...developer,
                            isCollapsed: false,
                        };
                    });
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
        updateStatus(e, id){
            Request.put('admin/updateStatus',id, {
                type : "order",
                status : e.target.value
            })
            .then( () => {
                this.getRequests();
            })
        },

        deleteOrder(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteorder(){
            this.deleteOrderLoading = true
            Request.get('admin/delete_order/'+this.deleteID)
                .then( () => {
                    this.deleteOrderLoading = false
                    this.getRequests();
                })
        },

        getOrderStatus(){
            axios.get(`https://app.almujtama.com.sa/admin/orderStatus`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }
            })
            .then((response) => {
                this.orderStatus = response.data.data
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getRequests(){
            Request.fetch('admin/orders', this.apiParams , {
                limit: store.state.limit
            })
            .then(response => {
                this.loadingRequest = false;
                this.orders = response.data.data
                this.pages = Math.ceil(response.data.total / store.state.limit) 
                this.orders = response.data.data.map(developer => {
                    return {
                        ...developer,
                        isCollapsed: false,
                        billCollapsed: false,
                    };
                });
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },

        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
        print () {
            this.$htmlToPaper('printMe');
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getRequests();
            } else{
                this.apiParams = `?page=${val}`;
                this.getRequests();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.Processing{
    color: #EEAF33;
}
.deliverd{
    color: #42FF68;
}
.cancel{
    color: #FF3D3D;
}
.tabel_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 20px 0;
    @media (max-width: 1200px) {
        width: 991px;
    }
    .block{
        font-size: 16px;
        color: #1E1E1E;
        font-weight: 900;
        font-family: flatMedium;
        width: calc(100% / 10);
        text-align: center;
    }
}
.detailsTable{
    .block{
        width: calc(100% / 10);
        @media (max-width: 1450px) {
            padding: 10px !important;
        }
        select{
        border: 0;
        font-size: 16px;
        // color: #EEAF33;
        cursor: pointer;
        option{
            color: #000;
        }
        
    }
    .useLink{
        color: #000;
        transition: .3s ease-in-out;
        &:hover{
            color: #D8D253;
        }
    }
    .detailsBtn,
    .reciteBtn{
        background-color: #78A28F;
        border-radius: 10px;
        padding: 5px 30px;
        color: #FFF;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 1450px) {
            padding: 5px 10px;
        }
        svg{
            margin-left: 10px;
        }
    }
    .reciteBtn{
        margin-top: 10px;
        background-color: #D8D253;
    }
    }

    .details_popUp{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #0000003c;
        display: flex;
        justify-content: center;
        align-items: center;
        .details_container{
            background-color: #FFF;
            border-radius: 10px;
            position: relative;
            padding: 30px 50px;
            width: 1100px;
            height: 90%;
            background-color: #FAFAFA;
            overflow-y: scroll;
            @media (max-width: 1199px) {
                width: 94%;
            }
            @media (max-width: 991px) {
                width: 97%;
            }
            @media (max-width: 576px) {
                padding: 40px 20px 30px 20px;
            }
            .close_popUp{
                position: absolute;
                top: 10px;
                left: 10px;
                cursor: pointer;
                svg{
                    font-size: 30px;
                }
            }

            .steps{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 100px;
                @media (max-width: 991px) {
                    padding: 0 20px;
                }
                @media (max-width: 400px) {
                    padding: 0;
                }
                .roundStep{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .number{
                        width: 56px;
                        height: 56px;
                        background-color: #78A28F;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        position: relative;
                        @media (max-width: 576px) {
                            width: 30px;
                            height: 30px;
                        }
                        .number_description{
                            position: absolute;
                            top: 61px;
                            @media (max-width: 400px) {
                                top: 38px;
                            }
                            > h3{
                                font-size: 15px;
                                color: #78A28F;
                                font-weight: 600;
                                text-align: center;
                                white-space: nowrap;
                                font-family: flatMedium;
                                margin-bottom: 0;
                                @media (max-width: 576px) {
                                    font-size: 10px;
                                }
                                @media (max-width: 400px) {
                                    font-size: 8px;
                                }
                            }
                            > p{
                                font-size: 10px;
                                color: #8B8B8B;
                                text-align: center;
                                margin-bottom: 0;
                                @media (max-width: 400px) {
                                    font-size: 8px;
                                }
                            }
                        }
                    }
                }
                .stepLine{
                    height: 4px;
                    background-color: #78A28F;
                    width: 100%;
                    margin: 0 10px;
                }
            }
            .order_details{
                display: flex;
                justify-content: space-between;
                border-radius: 10px;
                background-color: #FFF;
                width: 100%;
                margin: 80px 0 20px 0;
                background-color: #FFF;
                flex-wrap: wrap;
                @media (max-width: 576px) {
                    margin: 100px 0 20px 0;
                }
                .block{
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    @media (max-width: 991px) {
                        width: 30%;
                    }
                    @media (max-width: 991px) {
                        width: 50%;
                    }
                    h4{
                        font-size: 16px;
                        color: #78A28F;
                        font-weight: 600;
                    }
                    h3{
                        font-size: 16px;
                        font-weight: 700;
                        font-family: flatMedium;
                        @media (max-width: 991px) {
                          font-size: 12px;
                        }
                    }
                    .des{
                        font-size: 18px;
                        font-weight: 600;
                         @media (max-width: 991px) {
                          font-size: 12px;
                        }
                    }
                    .reciteBtn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin-top: 0;
                    }
                }
                .alignFlex{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .clientStatus{
            background-color: #FFF;
            padding: 10px;
            border-radius: 10px;
            .block_header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                    font-size: 19px;
                    color: #78A28F;
                    font-weight: 600;
                    @media (max-width: 400px) {
                       font-size: 16px; 
                    }
                }
                svg{
                    cursor: pointer;
                }
            }
            .block_body{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 30px;
                .detail{
                    @media (max-width: 400px) {
                        width: 50%;
                        margin-bottom: 20px;
                    }
                    h4{
                        font-size: 16px;
                        font-weight: 900;
                        color: #1E1E1E;
                        text-align: center;
                        @media (max-width: 400px) {
                            font-size: 14px; 
                        }
                    }
                    h3{
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        @media (max-width: 400px) {
                            font-size: 14px; 
                        }
                    }
                }
            }
        }

        .popUp_order_details{
            background-color: #FFF;
            border-radius: 10px;
            margin-top: 40px;
            @media (max-width: 768px) {
                width: 100%;
                overflow-x: scroll;
            }
            .order_header{
                padding: 10px 20px;
                h3{
                    font-size: 19px;
                    color: #78A28F;
                    font-weight: 900;
                    @media (max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }
            .order_body,
            .order_table_header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                @media (max-width: 991px) {
                    margin-bottom: 0;
                }
                @media (max-width: 768px) {
                    width: 700px;
                }
                .block{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    width: calc(100% / 7);
                    padding: 0;
                    .img_container{
                        width: 110px;
                        height: 110px;
                        img{
                            width: 110px;
                            max-height: 100%;
                        }
                    }
                    h4{
                        font-size: 16px;
                        color: #78A28F;
                        font-weight: 600;
                        background-color: #FAFAFA;
                        width: 100%;
                        padding: 10px 0;
                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                    h3{
                        font-size: 16px;
                        font-weight: 600;
                        font-family: flatMedium;
                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .money{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 576px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .detailed_Money{
                background-color: #FFF;
                border-radius: 10px;
                margin-top: 40px;
                padding: 20px 30px;
                width: 314px;
                @media (max-width: 576px) {
                    margin-bottom: 30px;
                }
                > div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    h3{
                        font-size: 16px;
                        font-weight: 900;
                    }
                }
                .breakLine{
                    height: 1px;
                    width: 100%;
                    background-color: #DDD;

                }
                .total{
                    h3:first-child{
                        color: #78A28F;
                    }
                }
            }
        }
    }
}
.Bill_container{
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    background-color: #FFF;
    box-shadow: 0 0 10px #ababab;
    z-index: 99;
    overflow: scroll;
    .header{
        svg{
            font-size: 40px;
            cursor: pointer;
        }
    }
    .bill_body{
        min-width: 1100px;
    }
}

.saveBtn{
    margin-top: 0;
    &:hover{
        color: #FFF;
    }
}
.table_row{
    flex-wrap: wrap;
}
.details{
    width: 100%;
    padding: 40px;
    .details_temp{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .temp{
            width: 186px;
            margin-top: 40px;
            .temp_line{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                font-size: 16px;
                color: #656565;
            }
            .temp_hr{
                height: 1px;
                background-color: #656565;
                width: 100%;
                margin: 20px 0;
            }
        }
    }
}
table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
}

table thead th {
  padding: 15px;
  border-bottom: 1px solid #000;
  font-weight: 900;
  color: #1D4D90;
  font-family: flatMedium;
  text-align: center;
}

table tr {
  background: #ffffff;
}

table tr td {
  padding: 15px;
  text-align: center;
}


</style>